import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Cta from "../components/cta"

const StratPage = () => (
    <Layout>
        <Seo 
            title="Startup strategy lessons"
            description="Practical advice and strategy for early stage startup founders. 15 Lessons on how to start a startup."
        />

<section role="main" className="bg-purple with-pad-sm white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="300"
                      data-sal-duration="400"
                      >Startup lessons for first time founders</h1>
                      <h2 className="hero-lead"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="400"
                      data-sal-duration="400"
                      >
                          15 lessons, and a bit of fun, for startup founders
                      </h2>
                      <div className="btn-row">
                      <button onClick={() => scrollTo('#slide1')} className="btn btn-lg btn-pink">
                          <span className="flex flex-ac down">
                          <ArrowRight />
                          </span>
                      </button>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <div className="bg2" id="slide1">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <h3><span className="highlight">Startups are hard work but think of</span>the money, the freedom, the self-actualisation</h3>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide2')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg3" id="slide2">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #1</span>
                            <h3><span className="highlight">Solve a real problem</span>No problem? No startup.</h3>
                            <p className="white">I got 99 problems and your startup needs to solve one of them.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide3')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg4" id="slide3">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #2</span>
                            <h3>Talk to people</h3>
                            <p className="white">Seriously. Speak to your target audience. How do you know if you are solving the right problems?</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide4')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg5" id="slide4">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #3</span>
                            <h3><span className="highlight">Make version 1 simpler</span>Kill features</h3>
                            <p className="white">Working on version 1? Make it simpler. Get focused. Build one great feature that people love.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide5')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg6" id="slide5">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #4</span>
                            <h3><span className="highlight">Read everything</span> Neil Patel writes</h3>
                            <p className="white">He's the digital marketing king.</p>
                            <p className="white">(Google him once you've read all the lessons)</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide6')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg7" id="slide6">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #5</span>
                            <h3><span className="highlight">Do things</span>that don't scale</h3>
                            <p className="white">Paul Graham said this (Google him).</p> 
                            <p className="white">He's talking about the early days. As you prove your model you can worry about automating everything.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide7')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg8" id="slide7">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #6</span>
                            <h3><span className="highlight">For f#ck sakes</span>FOCUS</h3>
                            <p>Focus on your target audience. Be in their headspace, always. And always focus on your current project. Nothing else matters.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide8')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg9" id="slide8">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #7</span>
                            <h3><span className="highlight">Get</span>Diiiiiirty</h3>
                            <p className="white">Be prepared to do everything. In a startup you're the office manager, the cleaner, the accountant, the product guy / girl, the marketer, the HR manager, and sometimes the coder too.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide9')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg10" id="slide9">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #8</span>
                            <h3><span className="highlight">Get</span>Customers</h3>
                            <p className="white">Don't start your journey looking for investment. Some do. Yay for them. Having customers is more powerful. Paying customers are cool. Investors love that sh#t. It makes them feel fuzzy and more likely to invest.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide10')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg11" id="slide10">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #9</span>
                            <h3>Preach</h3>
                            <p className="white">Sing it from the rooftops. Pitch. Get in peoples faces. Never stop talking about your product. Put your laptop in front of people and make them sign up. Don't hide behind an NDA.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide11')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg12" id="slide11">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #10</span>
                            <h3><span className="highlight">Go green</span>green buttons convert the best</h3>
                            <p className="white">There are real people who test this stuff. They watch Star Wars on repeat and hang from the ceiling, but purple is definitely in. You should test too. Always!</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide12')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg13" id="slide12">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #11</span>
                            <h3><span className="highlight">Change to orange</span>Orange buttons are 2nd best</h3>
                            <p>If purple simply won't work for your design then go orange. It's the second highest converting button colour.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide13')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg14" id="slide13">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #12</span>
                            <h3>Be Lean</h3>
                            <p className="white">Get to market quickly with less money spent. Then learn from your users &amp; iterate accordingly.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide14')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg4" id="slide14">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #13</span>
                            <h3><span className="highlight">Don't (or do)</span>work at home</h3>
                            <p className="white">Surround yourself with people. You need to talk to people and discuss your product. Startup focused co-working spaces work well. Coffee shops are ok too, but try co-working. You'll find other smart people there.</p>
                            <small className="white">*written pre-Covid. Working from home is good but don't isolate yourself or your ideas.</small>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide15')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg5" id="slide15">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #14</span>
                            <h3><span className="highlight">Get the right</span>Team</h3>
                            <p className="white">You either need a great co-founder or a great team of suppliers. Actually, they need to be more than suppliers ... partners. You need great developers, great marketers, and a team that will go the extra mile with you.</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide16')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg15" id="slide16">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <span className="lesson-number">Lesson #15</span>
                            <h3><span className="highlight">You are</span>Awesome!</h3>
                            <p className="white">Never stop believing in yourself. If you believe in what you are doing, keep going. Remember that some of the best investors didn't like the biggest startups in the world when they were first approached. Set backs are part of the journey. Keep going!</p>
                            <div className="scroll-btn-row">
                                <button onClick={() => scrollTo('#slide17')} className="btn btn-lg btn-white">
                                    <span className="flex flex-ac down">
                                        <ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-purple" id="slide17">
            <div className="container">
                <div className="row">
                    <div className="strat-section">
                        <div className="strat-content">
                            <h3>Need help developing your startup?</h3>
                            <p className="white">Launch Lab are <Link to="/startup-web-application-development/">startup application developers</Link> in Australia.</p>
                            <p className="white">We design and develop scalable websites and applications for startups</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Cta 
            title="Work with Launch Lab"
            text="Get a web design quote and / or a web development quote from a local onshore Australian design & development team"
        />
    </Layout>
)

export default StratPage